import { PropsWithChildren, useMemo } from 'react';

import { useConfig } from '@clh/ui';

import { useSession } from '../use-session';

import {
    FeatureFlagsContext,
    FeatureFlagsContextValue,
} from './feature-flags-context';

export const FeatureFlagsProvider: React.FC<PropsWithChildren> =
    function FeatureFlagsProvider(props: PropsWithChildren) {
        const { currentUser } = useSession();
        const config = useConfig();

        const contextValue = useMemo(() => {
            const flags =
                config.featureFlags?.reduce<FeatureFlagsContextValue>(
                    (acc, f) => {
                        acc[f as keyof FeatureFlagsContextValue] = true;
                        return acc;
                    },
                    {}
                ) || {};

            if (
                flags.TB_SCREENING &&
                currentUser &&
                currentUser.createdAt < (config.tbScreeningRequireDate as Date)
            ) {
                flags.TB_SCREENING = false;
            }

            return flags;
        }, [config.featureFlags, currentUser]);

        return (
            <FeatureFlagsContext.Provider value={contextValue}>
                {props.children}
            </FeatureFlagsContext.Provider>
        );
    };
